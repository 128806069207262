<template>
  <div class="performance-rankings">
    <NormForm
      ref="normForm"
      v-model="queryForm"
      class="norm-form"
      :rules="rules"
      :query-btn-loading="loading"
      no-clear
      @refNode="(dom) => queryFormRef = dom"
      @query="handleQueryForm"
    >
      <el-form-item label="规则名称" prop="performanceRuleId">
        <NormSelection
          v-model="queryForm.performanceRuleId"
          api-key="performanceRule"
          placeholder="请选择规则名称"
          :data-method="(data) => data.filter(item => item.performanceRuleStatus === 1)"
          @responseData="(options) => { ruleOptions = options } "
        />
      </el-form-item>
    </NormForm>
    <vxe-table
      ref="xTable"
      v-loading="loading"
      border
      :show-overflow="false"
      class="editTable"
      max-height="550px"
      align="center"
      :data="tableData"
    >
      <vxe-table-column field="nickName" title="指标" min-width="100" />
      <vxe-table-column v-for="item in heardTitle" :key="item.performanceFieldId" field="style" :title="`${item.performanceFieldName}(${item.ruleWeight} %) `" min-width="100">
        <template v-slot="{ row }">
          <vxe-input
            v-if="row.type === 'input'"
            v-model="row[`performance-${item.performanceFieldId}`]"
            class="table-input"
            min="0"
            max="100000"
            type="float"
            size="mini"
          />
          <div v-else>{{ item.ruleMaxValue }}</div>
        </template>
      </vxe-table-column>
    </vxe-table>
    <el-button v-if="tableData.length > 0" :loading="addLoading" type="primary" style="display: flex;margin: 0 auto;" class="mt-5" @click="handleAddFieldScore">确认</el-button>
  </div>
</template>

<script>
import NormForm from '@/components/NormForm'
import NormSelection from '@/components/NormSelection'
import { omit } from 'lodash'
import {
  getFieldDetailByRuleId,
  getEmployee,
  addFieldScore
} from '@/api/performance'
export default {
  components: {
    NormForm,
    NormSelection
  },
  data() {
    return {
      queryFormRef: {},
      queryForm: {
        performanceRuleId: ''
      },
      freezeQueryForm: {
        performanceRuleId: ''
      },
      rules: {
        performanceRuleId: [{ required: true, message: '请选择规则名称', trigger: ['change', 'blur'] }]
      },
      loading: false,
      addLoading: false,
      tableData: [],
      ruleOptions: [],
      heardTitle: []
    }
  },
  computed: {
    getFieldDetailByRuleIdParams() {
      return { performanceRuleId: this.freezeQueryForm.performanceRuleId }
    },
    addFieldScoreParams() {
      const editData = this.tableData.filter(item => item.type === 'input')
        .reduce((acc, cur) => {
          this.heardTitle.forEach(item => {
            acc.push({
              employeeId: cur.userId,
              employeeName: cur.nickName,
              performanceRuleId: cur.performanceRuleId,
              performanceRuleName: cur.performanceRuleName,
              performanceFieldId: item.performanceFieldId,
              performanceFieldName: item.performanceFieldName,
              performanceScore: cur[`performance-${item.performanceFieldId}`]
            })
          })
          return acc
        }, [])
      return editData
    }
  },
  mounted() {
  },
  methods: {
    handleQueryForm() {
      this.freezeQueryForm.performanceRuleId = this.queryForm.performanceRuleId
      this._getFieldDetailByRuleId()
    },
    handleAddFieldScore() {
      if (this.addFieldScoreParams.filter(item => item.performanceScore === '').length > 0) {
        this.$message.warning('请填写所有录入指标')
        return
      }
      this.$confirm('是否确认录入指标?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        try {
          this.addLoading = true
          const { msg } = await addFieldScore(this.addFieldScoreParams)
          this.$message.success(msg)
          Object.assign(this.$data, omit(this.$options.data.call(this), ['queryFormRef']))
          this.$nextTick(() => {
            this.queryFormRef.clearValidate()
          })
        } finally {
          this.addLoading = false
        }
      }).catch(() => {})
    },
    async _getFieldDetailByRuleId() {
      try {
        this.loading = true
        const { datas } = await getFieldDetailByRuleId(this.getFieldDetailByRuleIdParams)
        this.heardTitle = datas
        const { datas: tableData } = await getEmployee()
        const { performanceRuleId } = this.freezeQueryForm
        const performanceRuleName = this.ruleOptions.find(item => item.performanceRuleId === performanceRuleId)?.performanceRuleName
        this.tableData = tableData
          .map(item => {
            const editItem = datas.reduce((acc, cur) => {
              acc[`performance-${cur.performanceFieldId}`] = ''
              return acc
            }, {})
            return { ...item, ...editItem, type: 'input', performanceRuleId, performanceRuleName }
          })
        this.tableData.unshift({ nickName: '目标', type: 'text' })
      } catch (error) {
        this.freezeQueryForm.performanceRuleId = ''
        this.heardTitle = []
        this.tableData = []
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.performance-rankings /deep/ {
  .norm-form .quarter-select {
    width: 400px;
    display: flex;
    .sw__wrapper {
      width: 200px;
    }
    .sw__trigger {
      overflow: hidden;
    }
  }
}
</style>
